import { useParams } from 'react-router-dom'
import { Maybe } from '../backbone/types'
import { useAppSelector } from '../redux/store'
import { useCurrentBlock } from './useCurrentBlock'
import { useCurrentForm } from './useCurrentForm'

export const useSiteTargetLink = (siteUuid: Maybe<string> = '') => {
  const { form: formIndex, blockIndex: currentBlockIndex } = useParams()
  const { form: remoteForm } = useCurrentForm()
  const { block: currentRemoteBlock } = useCurrentBlock()
  const targetSite = remoteForm?.blocks
    .flatMap((b) => b.sites.map((s) => ({ ...s, blockUuid: b.uuid })))
    .find((s) => s.uuid === siteUuid)

  const firstContainingBlockIndex = useAppSelector((s) =>
    s.input.findIndex((b) => b.blockUuid === targetSite?.blockUuid)
  )
  const isSiteInCurrentBlock =
    targetSite?.blockUuid === currentRemoteBlock?.uuid

  const targetBlockIndex = isSiteInCurrentBlock
    ? parseInt(currentBlockIndex || '')
    : firstContainingBlockIndex || firstContainingBlockIndex

  const target = `/${formIndex}/${targetBlockIndex}/${targetSite?.position}`

  return siteUuid ? target : undefined
}
