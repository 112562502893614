import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useCurrentForm } from '../hooks/useCurrentForm'
import { useAppSelector } from '../redux/store'
import { evalCondition, getTargetValueMapString } from './useEvalCondition'

export const useSidebarBlockLinkItems = () => {
  const { form } = useCurrentForm()
  const { blockIndex } = useParams()
  const parsedBlockIndex = parseInt(blockIndex || '')

  const state = useAppSelector((s) => s)

  const actualValueMap = useAppSelector((s) => JSON.stringify(s.valueMap))
  const targetValueMap = useMemo(() => getTargetValueMapString(state), [])

  const items = state.input
    .map(({ blockUuid, isComplete }, index) => ({
      ...form?.blocks.find((b) => b.uuid === blockUuid)!!,
      isComplete,
      isActive: parsedBlockIndex === index,
      index,
      path: `/${form?.uuid}/${index}`,
    }))
    .map((b) => ({
      ...b,
      matchesNotCondition: !evalCondition(
        b.condition || [],
        actualValueMap,
        targetValueMap
      ),
    }))

  return items
}
