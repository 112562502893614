import React from 'react'
import { useEnvLinks } from '../hooks/useStartPageLink'
import { useResetForm } from '../hooks/useResetForm'
import { useMatch } from 'react-router-dom'

export const Footer = () => {
  const { resetForm } = useResetForm()
  const { startPageUrl } = useEnvLinks()
  const isSuccessPage = !!useMatch('/success')
  return (
    <div className="border-t border-borderGray h-16 bg-white fixed bottom-0 z-10 w-full">
      <footer className="h-full flex items-center max-w-8xl w-full mx-auto md:px-8 tablet:px-4 justify-between">
        <div className="inline-block py-2.5 md:w-24 tablet:w-80 flex justify-start items-center md:mr-3 tablet:mr-8">
          <a
            className="p-0 text-lg text-font"
            href={`${startPageUrl}/impressum`}
          >
            Impressum
          </a>
        </div>

        {!isSuccessPage && (
          <span
            className="clickable text-lg mr-auto font-normal text-font"
            onClick={resetForm}
          >
            Eingaben löschen
          </span>
        )}
        {!isSuccessPage && (
          <span className="clickable text-lg font-normal text-font">
            Speichern
          </span>
        )}
      </footer>
    </div>
  )
}
