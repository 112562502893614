import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SnackbarProps } from '../shared/Snackbar'

interface State {
  snackbar: SnackbarProps
}
const initialState: State = { snackbar: {} }

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showSnackbar: (state, action: PayloadAction<SnackbarProps>) => {
      state.snackbar = action.payload
    },
  },
})

export const uiReducer = uiSlice.reducer

export const { showSnackbar } = uiSlice.actions
