import React, { FC } from 'react'
import { Select } from '../backbone/types'
import { FieldViewProps } from '../hooks/useFieldMapping'
import { PulldownField } from './PulldownField'
import { SelectFieldWithButtons } from './SelectFieldWithButtons'

export interface SelectFieldViewProps {
  value?: any
  onChange?: (v: any) => void
  input: Select
}

export const SelectField: FC<FieldViewProps> = ({ input, ...props }) => {
  const select = input as Select
  return (
    <div className={'flex flex-col items-center w-full form-input-regular form-input-regular-select ' + (select.isBranchingPoint ? 'form-input-regular-select-buttons' : '')}>
      {select.isBranchingPoint ? (
        <SelectFieldWithButtons input={select} {...props} />
      ) : (
        <PulldownField input={select} {...props} />
      )}
    </div>
  )
}
