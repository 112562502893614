import { FC } from 'react'
import { useUuid } from '../hooks/useUuid'

interface Props {
  label?: string
  className?: string
  value?: string
  onChange?: (v: string) => void
  labelClassName?: string
  _uuid?: string
}

export const MaterialInput: FC<Props> = ({
  label,
  value,
  onChange,
  className,
  _uuid,
  labelClassName = '',
}) => {
  const generatedUuid = useUuid()
  const uuid = _uuid || generatedUuid
  return (
    <div
      className={'flex items-center justify-center self-center ' + className}
    >
      {!!label && (
        <label
          htmlFor={uuid}
          className={'form-label inline-block text-gray-700 ' + labelClassName}
        >
          {label}
        </label>
      )}
      <input
        value={value || ''}
        onChange={(e) => onChange?.(e.currentTarget.value)}
        type="text"
        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none shadow-md leading-7"
        id={uuid}
      />
    </div>
  )
}
