import { useQuery } from '@apollo/client'
import { GET_ALL_FORMS } from '../backbone/queries'
import { Folder, QueryAllFoldersArgs } from '../backbone/types'

export const useAllForms = () => {
  const query = useQuery<{ allFolders: Folder[] }, QueryAllFoldersArgs>(
    GET_ALL_FORMS
  )
  return {
    forms: query.data?.allFolders.flatMap((f) => f.forms),
    ...query,
  }
}
