import { useMemo } from 'react'
import { Select, Statement } from '../backbone/types'
import { RootState, useAppSelector } from '../redux/store'

export const resolveStatementValues = (
  actualValueMap: {
    [uuid: string]: string
  },
  targetValueMap: {
    [uuid: string]: string
  }
) => (stmt: Statement) => {
  const { target: field, operand, value = '', con } = stmt
  const actualValue = actualValueMap[field] || '0'
  const targetValue = targetValueMap[value] || value
  const statementString = `'${actualValue}' ${operand} '${targetValue}' ${con || ''
    }`
  return statementString
}

export const getTargetValueMapString = (state: RootState) => {
  const optionMap = JSON.stringify(
    state.formCache.blocks
      .flatMap((b) => b.sites)
      .flatMap((s) => s.inputs)
      .filter((i) => i.__typename === 'Select')
      .flatMap((s) => (s as Select).options)
      .reduce((temp, cv) => ({ [cv.uuid]: cv.value, ...temp }), {})
  )

  return optionMap
}

export const evalCondition = (
  condition: Statement[],
  actualValueMap: string,
  targetValueMap: string
) => {
  if (!condition?.length) return true
  const parsedCondition = condition
    .map(
      resolveStatementValues(
        JSON.parse(actualValueMap),
        JSON.parse(targetValueMap)
      )
    )
    .join('')

  try {
    return !!eval(parsedCondition)
  } catch {
    console.error(parsedCondition)
    return true
  }
}

export const useEvalCondition = (condition: Statement[]) => {
  const state = useAppSelector((s) => s)
  if (!condition.length) return true

  const targetValueMap = useMemo(() => getTargetValueMapString(state), [])
  const actualValueMap = useAppSelector((s) => JSON.stringify(s.valueMap))

  return evalCondition(condition, actualValueMap, targetValueMap)
}
