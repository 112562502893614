import React, { FC } from 'react'
import { FieldViewProps } from '../hooks/useFieldMapping'
import { MaterialCheckbox } from '../shared/MaterialCheckbox'

export const CheckBoxField: FC<FieldViewProps> = ({
  input,
  onChange,
  value = false,
}) => {
  return (
    <MaterialCheckbox
      className="mb-3 mx-auto flex justify-center text-lg text-gray-400"
      checked={value}
      onClick={() => onChange?.(!value)}
    >
      {input.label}
    </MaterialCheckbox>
  )
}
