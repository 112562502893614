import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useCurrentBlock } from './useCurrentBlock'
import { useSetSiteCompleted } from './useSetSiteCompleted'

export const useCurrentSite = () => {
  const { blockIndex = '0', siteIndex = '' } = useParams()
  const parsedBlockIndex = parseInt(blockIndex)
  const parsedSiteIndex = parseInt(siteIndex)
  const setSiteCompleted = useSetSiteCompleted(
    parsedBlockIndex,
    parsedSiteIndex
  )

  useEffect(() => {
    setSiteCompleted(false)
  }, [])

  const { block, loading } = useCurrentBlock()
  return {
    loading,
    site: block?.sites.find((s) => s.position === parseInt(siteIndex)),
  }
}
