import React from 'react'
import { useSidebarBlockLinkItems } from '../hooks/useSidebarBlockLinkItems'
import { SidebarLink } from './SidebarLink'
import { SidebarSiteLinks } from './SidebarSiteLinks'

export const SidebarBlockLinks = () => {
  const items = useSidebarBlockLinkItems()
  return (
    <>
      {items.map((b) => (
        <ul className="flex flex-col mb-5 border-l-borderThick" key={b.index}>
          <SidebarLink {...b} />
          {b.isActive && (
            <SidebarSiteLinks
              linkPrefix={b.path}
              block={b}
              blockIndex={b.index}
            />
          )}
        </ul>
      ))}
    </>
  )
}
