import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const fileSlice = createSlice({
  name: 'files',
  initialState: [] as string[],
  reducers: {
    addFile: (state, action: PayloadAction<string>) => {
      if (state.includes(action.payload)) return state
      state.push(action.payload)
    },
    resetFiles: () => [],
  },
})

export const {
  actions: { addFile, resetFiles },
  reducer: fileReducer,
} = fileSlice
