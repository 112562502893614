import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Sidebar } from './Sidebar'

export const SidebarRouter = () => {
  return (
    <Routes>
      <Route path="/:form/:blockIndex/:siteIndex" element={<Sidebar />} />
      <Route path="/*" element={<div></div>} />
    </Routes>
  )
}
