import { client } from '../backbone/client'
import { useDispatch } from 'react-redux'
import { resetFiles } from '../redux/files'
import { clear } from '../redux/formCache'
import { reset as resetInput } from '../redux/input'
import { reset as resetValueMap } from '../redux/valueMap'
import { AppDispatch } from '../redux/store'

export const useResetState = () => {
  const dispatch = useDispatch<AppDispatch>()

  return {
    resetState: () => {
      dispatch(clear())
      dispatch(resetInput())
      dispatch(resetValueMap())
      dispatch(resetFiles())
      client.resetStore()
    },
  }
}
