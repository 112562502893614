import { FC, SVGProps } from 'react'

export const BurgerMenuIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="40" 
    height="40" 
    viewBox="0 0 40 40" 
    fill="currentColor" 
    aria-labelledby="n-icon-menu-mobile" 
    role="img"
    {...props}
  >
    <title 
      id="n-icon-menu-mobile">
        Icon Menu Mobile
    </title>
    <path 
      d="M38.3333 21.6668H1.66667C0.746667 21.6668 0 20.9218 0 20.0002C0 19.0785 0.746667 18.3335 1.66667 18.3335H38.3333C39.2533 18.3335 40 19.0785 40 20.0002C40 20.9218 39.2533 21.6668 38.3333 21.6668Z" 
      fill="currentColor"/>
    <path 
      d="M38.3333 10.0001H1.66667C0.746667 10.0001 0 9.25508 0 8.33341C0 7.41175 0.746667 6.66675 1.66667 6.66675H38.3333C39.2533 6.66675 40 7.41175 40 8.33341C40 9.25508 39.2533 10.0001 38.3333 10.0001Z" 
      fill="currentColor"/>
    <path 
      d="M20 33.3333H1.66667C0.746667 33.3333 0 32.5883 0 31.6667C0 30.745 0.746667 30 1.66667 30H20C20.92 30 21.6667 30.745 21.6667 31.6667C21.6667 32.5883 20.92 33.3333 20 33.3333Z" 
      fill="currentColor"/>
  </svg>
)
