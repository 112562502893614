export const helpText = `

Sie haben Fragen zu unserem Assistenten oder benötigen Hilfe beim Ausfüllen? Bitte melden Sie sich bei uns:

**Kontakt**

Notar Dr. Christoph Huhn  
Sieglarer Straße 2c  
53840 Troisdorf  

Telefon: 02241 – 252050  
Telefax: 02241 – 2520529  
E-Mail: [mail@notar-huhn.de](mailto:mail@notar-huhn.de)

**Öffnungszeiten**

Mo–Do: 08:30–17:30 Uhr  
Fr: 08:30–17:00 Uhr  
Termine außerhalb der Bürozeiten möglich
`
