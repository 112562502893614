import { setSiteCompletedFlag, unsetSiteCompletedFlag } from '../redux/input'
import { useAppDispatch, useAppSelector } from '../redux/store'

export const useSetSiteCompleted = (
  currentBlockIndex: number,
  currentSiteIndex: number
) => {
  const dispatch = useAppDispatch()
  const currentState = useAppSelector((s) =>
    s.input[currentBlockIndex]?.completedSites?.includes(currentSiteIndex)
  )
  return (isComplete: boolean) => {
    if (currentState !== isComplete) {
      if (isComplete)
        dispatch(
          setSiteCompletedFlag({
            currentBlockIndex,
            currentSiteIndex,
          })
        )
      else
        dispatch(
          unsetSiteCompletedFlag({
            currentBlockIndex,
            currentSiteIndex,
          })
        )
    }
  }
}
