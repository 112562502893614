import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../redux/store'
import { useCurrentForm } from './useCurrentForm'
import { useSetBlockCompleted } from './useSetBlockCompleted'

export const useCurrentBlock = () => {
  const { blockIndex = '' } = useParams()
  const { form, loading } = useCurrentForm()
  const index = parseInt(blockIndex)
  const setUncompleted = useSetBlockCompleted(parseInt(blockIndex), false)
  useEffect(setUncompleted, [setUncompleted, blockIndex])

  const formStateBlock = useAppSelector((state) => state.input[index] || {})
  const block = form?.blocks.find((b) => b.uuid === formStateBlock?.blockUuid)

  return { block, loading, index }
}
