import React, { FC, useRef } from 'react'
import ReactModal from 'react-modal'
import { useClickAwayListener } from '../hooks/useClickAwayListener'
import { CloseIcon } from '../icons/CloseIcon'

interface Props {
  open: boolean
  setOpen: (v: boolean) => void
  bodyClassName?: string
  containerClassName?: string
  headerRenderFn?: () => void
}

export const Modal: FC<Props> = ({
  open,
  setOpen,
  children,
  bodyClassName,
  containerClassName,
  headerRenderFn = () => null,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  useClickAwayListener(ref, () => setOpen(false))

  return (
    <ReactModal
      appElement={document.getElementById('root') as HTMLElement}
      isOpen={open}
      className="w-screen min-h-screen flex items-center justify-center fixed-top"
    >
      <div ref={ref}>
        <div
          className={`rounded min-h-240 min-w-700 shadow bg-ciLightBlue overflow-hidden flex flex-col p-5 ${containerClassName}`}
        >
          <div
            className="clickable flex items-center fill-current"
            onClick={() => setOpen(false)}
          >
            {headerRenderFn()}
            <CloseIcon className="ml-auto" />
          </div>
          <div className="flex flex-col py-2">
            <div className={'overflow-auto py-4 ' + bodyClassName}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}
