import React, { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Option } from '../backbone/types'
import { useEvalCondition } from '../hooks/useEvalCondition'
import { useSetBlockCompleted } from '../hooks/useSetBlockCompleted'
import { useSetSiteCompleted } from '../hooks/useSetSiteCompleted'
import { useSiteTargetLink } from '../hooks/useSiteTargetLink'
import { InfoIcon } from '../icons/InfoIcon'
import { Button } from '../shared/Button'
import { HelpTextView } from './HelpTextView'

interface Props {
  option: Option
  selected: boolean
  onSelect: () => void
}

export const OptionButton: FC<Props> = ({
  option: { label, siteLinkUuid, helpText, condition },
  onSelect,
  selected,
}) => {
  const [showHelp, setShowHelp] = useState(false)
  const { blockIndex = '0', siteIndex = '' } = useParams()
  const navigate = useNavigate()
  const parsedBlockIndex = parseInt(blockIndex)
  const parsedSiteIndex = parseInt(siteIndex)
  const setBlockCompleted = useSetBlockCompleted(parsedBlockIndex, true)
  const setSiteCompleted = useSetSiteCompleted(
    parsedBlockIndex,
    parsedSiteIndex
  )

  const target = useSiteTargetLink(siteLinkUuid)

  const onClick = () => {
    onSelect()
    if (target) {
      navigate(target)
      setBlockCompleted()
      setSiteCompleted(true)
    }
  }

  const conditionMatches = useEvalCondition(condition)
  if (!conditionMatches) return null

  return (
    <div className="flex-initial basis-[30%]">
      <Button
        disabled={!!siteLinkUuid && !target}
        className={`!text-white !bg-accent hover:!bg-cyan-800 min-h-180 min-w-250 text-2xl leading-7 font-medium relative rounded-xl flex flex-col justify-center content-center my-0 !p-0 shadow-md shadow-black/20 hover:opacity-100 n--option-button ${
          selected && '!bg-cyan-800'
        }`}
        onClick={onClick}
      >
        <div className="px-4 py-9 h-selectOptionHeight flex justify-center items-center mx-auto">
          {label}
        </div>
        {helpText && (
          <div
            onClick={(e) => {
              e.stopPropagation()
              setShowHelp(!showHelp)
            }}
            className="clickable bg-ciLightBlue hover:bg-ciLightBlue hover:opacity-100 text-font px-4 py-4 w-full space-x-2 rounded-b-xl flex justify-center content-center items-center"
          >
            <InfoIcon />
            <span className="!ml-selectOptionInfoSpacing">Info</span>
          </div>
        )}
      </Button>
      {helpText && (
        <HelpTextView
          helpText={helpText}
          open={showHelp}
          setOpen={setShowHelp}
        />
      )}
    </div>
  )
}
