import { Maybe } from '../backbone/types'

export const getColumnWidthClass = (cols: Maybe<number> | undefined) => {
  /*
    NOTE: PLEASE DO NOT REFACTOR!
    Tailwind can only handle statically extractable classes.
    https://stackoverflow.com/questions/69687530/dynamically-build-classnames-in-tailwindcss
    */
  const classes = [
    'w-[500px]',
    'w-[83px]',
    'w-[167px]',
    'w-[250px]',
    'w-[333px]',
    'w-[417px]',
    'w-[500px]',
  ]
  return classes[cols || 0]
}
