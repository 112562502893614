import { FC, SVGProps } from 'react'

export const BackArrow: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg 
    xmlns='http://www.w3.org/2000/svg' 
    width='16' 
    height='16' 
    viewBox='0 0 16 16' 
    fill='currentColor' 
    aria-labelledby='n-icon-arrow-left-large' 
    role='img'
    {...props}>
    <title 
      id='n-icon-arrow-left-large'
    >Icon Arrow Left Large
    </title>
    <path 
      d='M11.1 0.299952L12.6 1.69995L6.29998 7.99995L12.6 14.3L11.1 15.7L3.39997 7.99995L11.1 0.299952Z' 
      fill='currentColor'
    />
  </svg>
)