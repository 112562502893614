import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { insertBlockByUuid } from '../redux/input'
import { AppDispatch } from '../redux/store'
import { useCurrentBlock } from './useCurrentBlock'

export const useInsertBlockByUuid = () => {
  const { blockIndex = '' } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const { block } = useCurrentBlock()

  return {
    insertBlock: () => {
      if (!!block?.uuid) {
        const payload = {
          currentBlockIndex: parseInt(blockIndex),
          blockUuid: block.uuid,
        }

        dispatch(insertBlockByUuid(payload))
      }
    },
  }
}
