import React from 'react'
import { BurgerMenuIcon } from '../icons/BurgerMenuIcon'

export function SidebarMenuButton({
  setShowMobile,
}: {
  setShowMobile: (v: boolean) => void
}) {
  return (
    <div className="h-16 min-w-60 bg-white">
      <BurgerMenuIcon
        onClick={() => setShowMobile(true)}
        className="tablet:hidden mt-4 clickable text-dark"
      />
      &nbsp;
    </div>
  )
}