import { FC, SVGProps } from 'react'

export const CloseIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="ed642368-73fe-4264-8374-5b4f08ac9206"
    data-name="Layer 1"
    width="26.8701"
    height="26.8701"
    fill="currentColor"
    viewBox="0 0 26.8701 26.8701"
    aria-labelledby="n-icon-close"
    role="img"
    {...props}
  >
    <title id="n-icon-close">Close Icon</title>
    <polygon points="26.87 2.121 24.749 0 13.435 11.314 2.121 0 0 2.121 11.314 13.435 0 24.749 2.121 26.87 13.435 15.556 24.749 26.87 26.87 24.749 15.556 13.435 26.87 2.121" />
  </svg>
)
