import React from 'react'
import { useParams } from 'react-router-dom'
import { Input } from '../backbone/types'
import { FieldProxy } from '../form/FieldProxy'

export const FieldGroup = ({ inputs }: { inputs: Input[]; group?: string }) => {
  const { blockIndex, siteIndex } = useParams()
  let rowFill = 0

  const items = inputs.map((i) => {
    rowFill = rowFill + (i.columns || 6)
    const isRowOverfull = rowFill > 6
    if (isRowOverfull) rowFill = i.columns || 6
    return {
      ...i,
      insertBreakBefore: isRowOverfull && i.__typename === 'TextField',
    }
  })

  return (
    <div className="mb-12 w-full ">
      <div className="flex flex-wrap w-full mx-auto justify-center items-center">
        {items.map(({ insertBreakBefore, ...input }, index) => (
          <React.Fragment key={`${blockIndex}-${siteIndex}-${index}`}>
            {insertBreakBefore && <div className="w-full h-0">&nbsp;</div>}
            <FieldProxy input={input} />
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
