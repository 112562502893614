import React, { FC } from 'react'
import { NavButton } from '../shared/NavButton'
import { FieldViewProps } from '../hooks/useFieldMapping'
import { useParams } from 'react-router-dom'
import { useCurrentBlock } from '../hooks/useCurrentBlock'
import { useSetBlockCompleted } from '../hooks/useSetBlockCompleted'
import { useSetSiteCompleted } from '../hooks/useSetSiteCompleted'

export const NextField: FC<FieldViewProps> = ({ input, onChange }) => {
  const { form, blockIndex = '', siteIndex = '' } = useParams()
  const { block } = useCurrentBlock()
  const parsedSiteIndex = parseInt(siteIndex)
  const nextSiteIndex = parsedSiteIndex + 1
  const parsedBlockIndex = parseInt(blockIndex)
  const nextSite = block?.sites?.find((s) => s.position === nextSiteIndex)

  const setBlockCompleted = useSetBlockCompleted(parsedBlockIndex, true)
  const setSiteCompleted = useSetSiteCompleted(
    parsedBlockIndex,
    parsedSiteIndex
  )

  const nextUrl = !!nextSite
    ? `/${form}/${blockIndex}/${nextSiteIndex}`
    : `/${form}/${parseInt(blockIndex) + 1}/0`
  return (
    <NavButton
      onAfterClick={() => {
        onChange?.('next')
        setBlockCompleted()
        setSiteCompleted(true)
      }}
      target={nextUrl}
    >
      {input.label}
    </NavButton>
  )
}
