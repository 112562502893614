import React from 'react'
import { Link } from 'react-router-dom'
import { useAllForms } from '../hooks/useAllForms'
import { LoadingIndicator } from '../shared/LoadingIndicator'

export const FormNav = () => {
  const { loading, forms } = useAllForms()
  const env = process.env.NODE_ENV || 'development'
  if (env === 'production') return null
  return loading ? (
    <LoadingIndicator />
  ) : (
    <>
      <div className="opacity-30 flex items-center justify-center w-screen">
        <h2>Diese Ansicht ist nur im Dev-Mode sichtbar!</h2>
      </div>
      {forms?.map((f, i) => (
        <Link key={i} to={f.uuid}>
          {f.label}
        </Link>
      ))}
    </>
  )
}
