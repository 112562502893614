import React from 'react'
import { useLocation } from 'react-router-dom'
import { useBreadcrumbs } from '../hooks/useBreadcrumbs'
import { Button } from '../shared/Button'

export const Success = () => {
  const { state }: { state: any } = useLocation()
  useBreadcrumbs([])
  const pdfDataUri = `data:application/pdf;base64,${state.pdf}`
  return (
    <div className="flex flex-col items-center space-y-4 mx-auto mt-successScreenSpacing">
      <h3 className="text-2xl font-semibold mb-2">Ihre Daten wurden erfolgreich übertragen.</h3>
      <p className="text-lg !mb-8">Sie können nun eine Zusammenfassung herunterladen:</p>
      <Button className="!text-white !bg-accent min-h-180 !w-navButtonWidth relative mx-auto !flex !font-medium 
      !text-2xl !justify-center !items-center !p-0 !clickable !px-4 !py-2 !rounded-xl !my-2 hover:opacity-100 hover:!bg-cyan-800">
        <a
          download
          href={pdfDataUri}
          className="h-full flex items-center"
        >
          Zusammenfassung herunterladen
        </a>
      </Button>
    </div>
  )
}
