import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { LoadFormHandler } from '../form/LoadFormHandler'
import { LoadBlockHandler } from '../form/LoadBlockHandler'
import { LoadSiteHandler } from '../form/LoadSiteHandler'
import { Error } from './Error'
import { FormNav } from './FormNav'
import { Breadcrumbs } from './Breadcrumbs'
import { Success } from './Success'

export const ContentRouter = () => {
  return (
    <div className="flex-1 ml-8 pt-0 w-full">
      <Breadcrumbs />
      <Routes>
        <Route path=":form" element={<LoadFormHandler />}></Route>
        <Route path=":form/:blockIndex" element={<LoadBlockHandler />}></Route>
        <Route
          path=":form/:blockIndex/:siteIndex"
          element={<LoadSiteHandler />}
        />
        <Route path="/error" element={<Error />} />
        <Route path="/success" element={<Success />} />
        <Route path="/*" element={<FormNav />} />
      </Routes>
    </div>
  )
}