import { FC } from 'react'
import { Input, InputType } from '../backbone/types'
import { LongTextField } from '../form/LongTextField'
import { SelectField } from '../form/SelectField'
import { TextField } from '../form/TextField'
import { NextField } from '../form/NextBlockField'
import { RepeatBlockField } from '../form/RepeatBlockField'
import { SubmitFormField } from '../form/SubmitFormField'
import { CheckBoxField } from '../form/CheckBoxField'
import { InfoField } from '../form/InfoField'
import { UploadField } from '../form/UploadField'

export interface FieldViewProps {
  value?: any
  onChange?: (v: any) => void
  input: Input
}

export type MappingKey = keyof typeof InputType

export const FieldTypeComponentMapping: {
  [key in MappingKey]: FC<FieldViewProps> | null
} = {
  [InputType.TextField]: TextField,
  [InputType.TextArea]: LongTextField,
  [InputType.CheckBox]: CheckBoxField,
  [InputType.FileUpload]: UploadField,
  [InputType.InfoField]: InfoField,
  [InputType.Select]: SelectField,
  [InputType.NextBlockButton]: NextField,
  [InputType.RepeatBlockButton]: RepeatBlockField,
  [InputType.SubmitFormButton]: SubmitFormField,
}

export const useFieldMapping = (type: MappingKey | undefined) =>
  !type ? null : FieldTypeComponentMapping[type]
