import React, { FC } from 'react'
import { NavButton } from '../shared/NavButton'
import { FieldViewProps } from '../hooks/useFieldMapping'
import { useInsertBlockByUuid } from '../hooks/useInsertBlockByUuid'
import { useParams } from 'react-router-dom'
import { useSetBlockCompleted } from '../hooks/useSetBlockCompleted'
import { useSetSiteCompleted } from '../hooks/useSetSiteCompleted'

export const RepeatBlockField: FC<FieldViewProps> = ({ input, onChange }) => {
  const { insertBlock } = useInsertBlockByUuid()
  const { form, blockIndex = '0', siteIndex = '' } = useParams()
  const parsedBlockIndex = parseInt(blockIndex)
  const parsedSiteIndex = parseInt(siteIndex)
  const setBlockCompleted = useSetBlockCompleted(parsedBlockIndex, true)
  const setSiteCompleted = useSetSiteCompleted(
    parsedBlockIndex,
    parsedSiteIndex
  )
  return (
    <NavButton
      onAfterClick={() => {
        onChange?.('repeat')
        setBlockCompleted()
        setSiteCompleted(true)
      }}
      target={`/${form}/${parseInt(blockIndex) + 1}`}
      onBeforeClick={insertBlock}
    >
      {input.label}
    </NavButton>
  )
}
