import { FC } from 'react'
import { Link, useMatch } from 'react-router-dom'
import { useBreadcrumbs } from '../hooks/useBreadcrumbs'
import { BreadcrumbItem } from './BreadcrumbItem'

export const Breadcrumbs: FC = () => {
  const { breadcrumbs } = useBreadcrumbs()
  const defaultRoot = { label: 'Tool', path: '/' }
  const [root = defaultRoot, ...rest] = breadcrumbs
  const isSuccessPage = !!useMatch('/success')

  return isSuccessPage ? null : (
    <div className="flex w-full h-16 items-center n--breadcrumbs">
      <Link className="text-lg" to={root.path}>
        {root.label}
      </Link>
      {rest.map((bc, i) => (
        <BreadcrumbItem key={i} {...bc} />
      ))}
    </div>
  )
}
