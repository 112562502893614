import React, { FC } from 'react'
import { TrashIcon } from '../icons/TrashIcon'

export const FileListItem: FC<{ label: string; remove: () => void }> = ({
  label,
  remove,
}) => {
  return (
    <div className="flex items-center justify-between">
      <span className="truncate">{label}</span>
      <TrashIcon className="clickable p-0.5" onClick={remove} />
    </div>
  )
}
