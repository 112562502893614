import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { helpText } from '../HelpText'
import { QuestionCircle } from '../icons/QuestionCircle'
import { MaterialButton } from '../shared/MaterialButton'
import { Modal } from '../shared/Modal'

const HelpButtonContent = () => (
  <>
    <QuestionCircle className="fill-current text-dark inline-block align-sub" />
    <span className="ml-1 text-lg">Hilfe</span>
  </>
)

export const HelpButton = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <MaterialButton
        className="!bg-white !text-iconColorDark !text-dark !shadow-none !p-0 !pl-0 mr-6 !py-1 md:w-16 tablet:w-30px flex justify-start items-center font-normal"
        onClick={() => setOpen(true)}
      >
        <HelpButtonContent />
      </MaterialButton>
      <Modal
        bodyClassName="max-h-screen"
        containerClassName="max-w-50vw rounded-md border border-accent bg-white"
        open={open}
        setOpen={setOpen}
        headerRenderFn={() => <HelpButtonContent />}
      >
        <div className="prose px-4">
          <ReactMarkdown>{helpText}</ReactMarkdown>
        </div>
      </Modal>
    </>
  )
}