import gql from 'graphql-tag'

const CONDITION_FIELDS = gql`
  {
    target
    con
    operand
    value
  }
`

const INPUT_BASE_FIELDS = gql`
  {
    __typename
    uuid
    columns
    position
    isRequired
    condition {
        ...${CONDITION_FIELDS}
    }
    group
    name
    label
    isLabelCentered
    helpText
  }
`

const INPUT_FIELDS = gql`{
    __typename
    ... on FileUpload {
     ...${INPUT_BASE_FIELDS}
    }
    ... on TextField {
     ...${INPUT_BASE_FIELDS}
    }
    ... on RepeatBlockButton {
     ...${INPUT_BASE_FIELDS}
    }
    ... on NextBlockButton {
     ...${INPUT_BASE_FIELDS}
    }
    ... on SubmitFormButton {
     ...${INPUT_BASE_FIELDS}
    }
    ... on TextArea {
     ...${INPUT_BASE_FIELDS}
    }
    ... on CheckBox {
     ...${INPUT_BASE_FIELDS}
    }
    ... on InfoField {
     ...${INPUT_BASE_FIELDS}
    }
    ... on Select {
     ...${INPUT_BASE_FIELDS}
      isBranchingPoint
      options {
        uuid
        label
        condition {
        ...${CONDITION_FIELDS}
        }
        value
        helpText
        siteLinkUuid
      }
    }
  }
`

const SITE_FIELDS = gql`{
    uuid
    name
    label
    position
    inputs ${INPUT_FIELDS}
  }`

const BLOCK_FIELDS = gql`
 {
      uuid
      name
      label
      condition {
        ...${CONDITION_FIELDS}
        }
      position
      sites ${SITE_FIELDS}
    }`

const FORM_FIELDS = gql`
  {
    uuid
    isDraft
    folderUuid
    label
    name
    blocks ${BLOCK_FIELDS}
  }
`

export const GET_ALL_FORMS = gql`
  query {
    allFolders {
      forms {
        uuid
        label
      }
    }
  }
`

export const SET_FORM_STATUS = gql`
  mutation setFormStatus($uuid: ID!, $isDraft: Boolean!) {
    setFormStatus(uuid: $uuid, isDraft: $isDraft) {
      uuid
    }
  }
`

export const SAVE_FORM = gql`
  mutation saveForm(
    $form: FormInput!
    $blocks: [BlockInput!]!
    $sites: [SiteInput!]!
    $fields: [FieldInput!]!
    $options: [OptionInput!]!
  ) {
    saveForm(
      form: $form
      blocks: $blocks
      sites: $sites
      fields: $fields
      options: $options
    ) ${FORM_FIELDS}
  }
`

export const UPLOAD_FILE = gql`
  mutation uploadFile($input: Upload!, $label: String) {
    uploadFile(input: $input, label: $label)
  }
`

export const MOVE_FORM = gql`
  mutation moveForm($formUuid: String!, $folderUuid: String!) {
    moveForm(formUuid: $formUuid, folderUuid: $folderUuid)
  }
`

export const GET_BLOCK = gql`
query getBlock($uuid: String!){
    block(uuid:$uuid){
        ...${BLOCK_FIELDS}
    }
}
`

export const GET_FORM = gql`
  query getForm($uuid: String!) {
    form(uuid: $uuid) 
      ${FORM_FIELDS}
  }
`

export const GET_ALL_FOLDERS = gql`
  query allFolders {
    allFolders {
      __typename
      uuid
      name
      lastUpdated
      label
    }
  }
`

export const GET_FOLDER = gql`
  query folder($uuid: String!) {
    folder(uuid: $uuid) {
      label
      forms {
        uuid
        label
        isDraft
        lastUpdated
      }
    }
  }
`

export const CREATE_FOLDER = gql`
  mutation createFolder($input: FolderInput!) {
    createFolder(input: $input) {
      uuid
      label
    }
  }
`

export const UPDATE_FOLDER = gql`
  mutation createFolder($uuid: ID!, $input: FolderInput!) {
    updateFolder(uuid: $uuid, input: $input) {
      uuid
      label
    }
  }
`

export const DELETE_FOLDER = gql`
  mutation createFolder($uuid: ID!) {
    deleteFolder(uuid: $uuid)
  }
`

export const SUBMIT_FORM = gql`
  mutation submitForm($input: SubmissionInput!) {
    submitForm(input: $input) {
      pdf
    }
  }
`
