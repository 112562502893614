import React from 'react'
import { Navigate } from 'react-router-dom'
import { useCurrentBlock } from '../hooks/useCurrentBlock'
import { LoadingIndicator } from '../shared/LoadingIndicator'

export const LoadBlockHandler = () => {
  const { block, loading } = useCurrentBlock()

  return loading ? (
    <LoadingIndicator />
  ) : (
    <Navigate to={!!block ? '0' : '/error'} replace={true} />
  )
}
