import React, { ButtonHTMLAttributes, FC } from 'react'

export enum ButtonColor {
  red = 'bg-red-400 text-white ',
  blue = 'bg-blue-400 text-white ',
  gray = 'bg-gray-200 text-black',
}

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonColor?: ButtonColor
}

export const Button: FC<Props> = ({
  buttonColor = ButtonColor.gray,
  className,
  children,
  ...props
}) => (
  <button
    {...props}
    className={`clickable ${buttonColor} px-4 py-2 rounded my-2 w-full max-w-2xl ${className}`}
  >
    {children}
  </button>
)
