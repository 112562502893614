import { client } from '../backbone/client'

export const useResetForm = () => {
  return {
    resetForm: () => {
      client.resetStore()
      localStorage.clear()
      window.location.reload()
    },
  }
}
