import React from 'react'
import { Navigate } from 'react-router-dom'
import { useCurrentSite } from '../hooks/useCurrentSite'
import { useSiteBreadCrumbs } from '../hooks/useSiteBreadCrumbs'
import { LoadingIndicator } from '../shared/LoadingIndicator'
import { SiteView } from './SiteView'

export const LoadSiteHandler = () => {
  const { site, loading } = useCurrentSite()
  useSiteBreadCrumbs()
  return loading ? (
    <LoadingIndicator />
  ) : !site ? (
    <Navigate to={`/error`} />
  ) : (
    <SiteView />
  )
}
