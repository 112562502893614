import { useCurrentSite } from './useCurrentSite'

export const useCurrentFieldGroups = () => {
  const { site } = useCurrentSite()

  return (
    Object.keys(
      (site?.inputs || [])
        .flatMap((i) => i.group)
        .map((g) => g as string)
        .filter((g) => !!g)
        .reduce((obj, key) => ({ ...obj, [key]: key }), {})
    ) || []
  )
}
