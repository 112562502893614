import React, { FC, MouseEvent } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../shared/Button'

interface Props {
  target?: string
  className?: string
  disabled?: boolean
  onBeforeClick?: () => void
  onAfterClick?: () => void
}

export const NavButton: FC<Props> = ({
  children,
  target,
  className,
  onBeforeClick,
  onAfterClick,
  disabled,
}) => {
  const { siteIndex = '0' } = useParams()
  const navigate = useNavigate()

  const onClick = async (e: MouseEvent) => {
    e.preventDefault()
    await onBeforeClick?.()
    await navigate(target || `../${parseInt(siteIndex) + 1}`)
    await onAfterClick?.()
  }
  return (
    <div className="w-full flex content-center form-input-button">
      <Button
        disabled={disabled}
        onClick={onClick}
        className={`!text-white !bg-accent min-h-180 w-navButtonWidth text-2xl leading-7 font-medium rounded-xl relative mx-auto mt-0 mb-4 justify-center content-center items-center flex flex-col px-4 hover:!bg-cyan-800 shadow-md shadow-black/20 hover:opacity-100 n--option-button ${className}`}
      >
        {children}
      </Button>
    </div>
  )
}
