import React, { FC } from 'react'
import { OptionButton } from './OptionButton'
import { SelectFieldHeader } from './SelectFieldHeader'
import { SelectFieldViewProps } from './SelectField'

export const SelectFieldWithButtons: FC<SelectFieldViewProps> = ({
  input,
  onChange,
  value,
}) => {
  return (
    <>
      <SelectFieldHeader select={input} />
      <div className="flex flex-wrap gap-4 grid-cols-2 mb-4 w-full justify-center">
        {input.options.map((o, i) => (
          <OptionButton
            onSelect={() => onChange?.(o?.value)}
            selected={value === o?.value}
            option={o!}
            key={i}
          />
        ))}
      </div>
    </>
  )
}
