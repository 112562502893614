import React, { FC, MouseEvent } from 'react'

interface Props {
  onClick: () => void
}
export const InfoButton: FC<Props> = ({ onClick }) => {
  const action = (e: MouseEvent) => {
    e.stopPropagation()
    onClick()
  }
  return (
    <div
      onClick={action}
      className="text-base rounded px-4 py-2 !ml-5 bg-accent clickable text-white flex space-x-2 items-center leading-5 hover:!bg-cyan-800 hover:!opacity-100"
    >
      <svg 
      width="22" 
      height="22" 
      viewBox="0 0 22 22" 
      fill="currentColor" 
      xmlns="http://www.w3.org/2000/svg" 
      aria-labelledby="n-icon-info" 
      role="img">
      <title id="n-icon-info">Icon Info</title>
      <rect width="22" height="22" fill="none"/>
      <path d="M11 1C5.5 1 1 5.5 1 11C1 16.5 5.5 21 11 21C16.5 21 21 16.5 21 11C21 5.5 16.5 1 11 1ZM12.25 16H9.75V9.75H12.25V16ZM11 8.5C10.25 8.5 9.75 8 9.75 7.25C9.75 6.5 10.25 6 11 6C11.75 6 12.25 6.5 12.25 7.25C12.25 8 11.75 8.5 11 8.5Z" fill="currentColor"/>
      </svg>
      <span>Info</span>
    </div>
  )
}
