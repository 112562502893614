import React, { FC } from 'react'
import { FieldViewProps } from '../hooks/useFieldMapping'
import { useFiles } from '../hooks/useFiles'
import { useSnackbar } from '../hooks/useSnackbar'
import { useUploadFile } from '../hooks/useUploadFile'
import { FileDropZone } from './FileDropZone'
import { FileList } from './FileList'

export const UploadField: FC<FieldViewProps> = ({
  input,
  onChange: setFiles,
  value: files = [],
}) => {
  const { showSnackbar } = useSnackbar({
    title: 'Fehler!',
    text: 'Es können nur Dateien vom Typ PDF und JPG hochladen.',
    timeout: 3000,
  })
  const [upload, { loading }] = useUploadFile()
  const { addFile } = useFiles()

  const onChange = (e: any) => {
    const file = e.target?.files?.[0] as File
    if (loading) return
    upload({
      variables: { input: file, label: file.name },
      onCompleted: ({ uploadFile }) => {
        setFiles?.([...files, file.name])
        addFile(uploadFile)
      },
      onError: () => {
        showSnackbar()
      },
    })
  }
  return (
    <div className="w-full mb-4 form-input-regular-upload">
      <div className="flex flex-col items-center mx-auto w-inputFieldWidth">
        <h3 className="text-lg mb-4">{input.label}</h3>
        <FileDropZone onChange={onChange} input={input} loading={loading} />
        <FileList files={files} setFiles={(v) => setFiles?.(v)} />
      </div>
    </div>
  )
}
