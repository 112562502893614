import { FC, HTMLProps } from 'react'

export const MaterialButton: FC<HTMLProps<HTMLButtonElement>> = ({
  className,
  children,
  ...Props
}) => {
  return (
    <button
      // @ts-ignore
      type="button"
      className={
        'inline-block px-3 py-2.5 bg-accent text-white font-medium leading-tight rounded shadow-md hover:bg-accent hover:shadow-lg focus:bg-accent focus:shadow-lg focus:outline-none focus:ring-0 active:bg-accent active:shadow-lg transition duration-150 ease-in-out clickable ' +
        className
      }
      {...Props}
    >
      {children}
    </button>
  )
}
