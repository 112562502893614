import { useDispatch } from 'react-redux'
import { Form } from '../backbone/types'
import { loadForm } from '../redux/input'
import { useAppSelector } from '../redux/store'

export const useInitializeInputState = () => {
  const dispatch = useDispatch()
  const formState = useAppSelector((state) => state.input)

  return (form: Form) => {
    if (!formState.length) dispatch(loadForm(form))
  }
}
