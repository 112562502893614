import { useDispatch } from 'react-redux'
import { setFieldValue } from '../redux/input'
import { setValue as setValueMapValue } from '../redux/valueMap'
import { useAppSelector } from '../redux/store'
import { useEffect } from 'react'

export const useField = (
  blockIndex: number,
  fieldUuid: string,
  isSelect: boolean = false
) => {

  const dispatch = useDispatch()

  const val = useAppSelector(
    (state) => state.input[blockIndex].values[fieldUuid]
  )  

  useEffect(() => {
    if (isSelect && val !== undefined && val !== null) {
      dispatch(setValueMapValue({ fieldUuid: fieldUuid, value: val }))
    }
  }, [isSelect, fieldUuid])

  const setValue = (value: any) => {

    if (isSelect) {
      dispatch(setValueMapValue({ fieldUuid, value }))
    }
    
    dispatch(
      setFieldValue({
        blockIndex,
        fieldUuid,
        value,
      })
    )
  }

  return { value: val, setValue }
}
