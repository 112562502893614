import React, { FC } from 'react'
import { Block } from '../backbone/types'
import { useSidebarSiteLinkItems } from '../hooks/useSidebarSiteLinkItems'
import { SidebarLink } from './SidebarLink'

interface Props {
  linkPrefix: string
  block: Block
  blockIndex: number
}

export const SidebarSiteLinks: FC<Props> = ({
  linkPrefix,
  block,
  blockIndex,
}) => {
  const items = useSidebarSiteLinkItems(blockIndex, block, linkPrefix)

  return (
    <>
      {items.map((s, i) => (
        <SidebarLink key={i} indent={true} {...s} />
      ))}
    </>
  )
}
