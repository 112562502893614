import { FC } from 'react'
import { Link } from 'react-router-dom'

export const BreadcrumbItem: FC<{ path: string; label: string }> = ({
  label,
  path,
}) => {
  return (
    <>
      <span className="px-1 leading-5">&raquo;</span>
      <Link className="text-lg leading-5" to={path}>{label}</Link>
    </>
  )
}
