import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { SUBMIT_FORM } from '../backbone/queries'
import { MutationSubmitFormArgs, Submission } from '../backbone/types'
import { useAppSelector } from '../redux/store'
import { useCurrentForm } from './useCurrentForm'
import { useResetState } from './useResetState'

export const useFormUuidLabelMapping = () => {
  const mapping = {} as { [key: string]: string }
  const formCache = useAppSelector((s) => s.formCache)

  if (formCache) {
    mapping[formCache.uuid] = formCache.label
    formCache.blocks.forEach((b) => {
      mapping[b.uuid] = b.label
      b.sites.forEach((s) => {
        mapping[s.uuid] = s.label
        s.inputs.forEach((i) => {
          mapping[i.uuid] = i.label
          if (i.__typename === 'Select')
            i.options.forEach((o) => {
              mapping[o!.uuid] = o!.label
            })
        })
      })
    })
  }
  return mapping
}

export const useSubmitForm = () => {
  const navigate = useNavigate()
  const { form } = useCurrentForm()
  const { resetState } = useResetState()
  const input = useAppSelector((state) => state.input)
  const mapping = useFormUuidLabelMapping()
  const payload = { input, mapping }
  const files = useAppSelector((state) => state.files) || []
  const [mutate, request] = useMutation<
    { submitForm: Submission },
    MutationSubmitFormArgs
  >(SUBMIT_FORM, {
    onCompleted: ({ submitForm = {} }) => {
      navigate('/success', { state: { ...submitForm } })
      resetState()
    },
  })

  const submit = () => {
    if (!!form && !!payload)
      mutate({
        variables: {
          input: {
            form_uuid: form.uuid,
            payload,
            files,
          },
        },
      })
  }

  return {
    ...request,
    submit,
  }
}
