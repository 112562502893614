export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Base64: any
  JSON: any
  Markdown: any
  Upload: any
}

/** a Block is a part of the form which contains multiple sites */
export type Block = {
  __typename?: 'Block'
  condition: Array<Statement>
  isTemplate: Scalars['Boolean']
  /** the label of the object which is shown to the user */
  label: Scalars['String']
  /** the name of the object which is used to identify it in code */
  name: Scalars['String']
  position: Scalars['Int']
  sites: Array<Site>
  uuid: Scalars['ID']
}

export type BlockInput = {
  condition?: InputMaybe<Array<InputMaybe<StatementInput>>>
  formUuid: Scalars['ID']
  isTemplate: Scalars['Boolean']
  label: Scalars['String']
  name: Scalars['String']
  position: Scalars['Int']
  uuid: Scalars['ID']
}

export type CheckBox = InputFields & {
  __typename?: 'CheckBox'
  /** A number between 1 and 6 determining the field's column width. */
  columns?: Maybe<Scalars['Int']>
  condition: Array<Statement>
  group?: Maybe<Scalars['String']>
  helpText?: Maybe<Scalars['String']>
  isLabelCentered?: Maybe<Scalars['Boolean']>
  isRequired?: Maybe<Scalars['Boolean']>
  /** the label of the object which is shown to the user */
  label: Scalars['String']
  /** the name of the object which is used to identify it in code */
  name: Scalars['String']
  position: Scalars['Int']
  uuid: Scalars['ID']
}

export type FieldInput = {
  columns: Scalars['Int']
  condition?: InputMaybe<Array<InputMaybe<StatementInput>>>
  group?: InputMaybe<Scalars['String']>
  helpText?: InputMaybe<Scalars['String']>
  isBranchingPoint?: InputMaybe<Scalars['Boolean']>
  isLabelCentered: Scalars['Boolean']
  isRequired?: InputMaybe<Scalars['Boolean']>
  label: Scalars['String']
  name: Scalars['String']
  position: Scalars['Int']
  siteUuid: Scalars['ID']
  type: Scalars['String']
  uuid: Scalars['ID']
}

export type FileUpload = InputFields & {
  __typename?: 'FileUpload'
  /** A number between 1 and 6 determining the field's column width. */
  columns?: Maybe<Scalars['Int']>
  condition: Array<Statement>
  group?: Maybe<Scalars['String']>
  helpText?: Maybe<Scalars['String']>
  isLabelCentered?: Maybe<Scalars['Boolean']>
  isRequired?: Maybe<Scalars['Boolean']>
  /** the label of the object which is shown to the user */
  label: Scalars['String']
  /** the name of the object which is used to identify it in code */
  name: Scalars['String']
  position: Scalars['Int']
  uuid: Scalars['ID']
}

export type Folder = {
  __typename?: 'Folder'
  forms: Array<Form>
  label: Scalars['String']
  lastUpdated: Scalars['Float']
  name: Scalars['String']
  uuid: Scalars['ID']
}

export type FolderFormsArgs = {
  filter?: InputMaybe<FormFilter>
}

export type FolderInput = {
  label: Scalars['String']
  name: Scalars['String']
}

export type FolderItem = Folder | Form

export type Form = {
  __typename?: 'Form'
  blocks: Array<Block>
  folderUuid: Scalars['String']
  /** the label of the object which is shown to the user */
  isDraft?: Maybe<Scalars['Boolean']>
  label: Scalars['String']
  lastUpdated: Scalars['Float']
  /** the name of the object which is used to identify it in code */
  name: Scalars['String']
  uuid: Scalars['ID']
}

export type FormFilter = {
  isDraft?: InputMaybe<Scalars['Boolean']>
  label?: InputMaybe<Scalars['String']>
  lastUpdated?: InputMaybe<Scalars['Float']>
  name?: InputMaybe<Scalars['String']>
  uuid?: InputMaybe<Scalars['ID']>
}

export type FormInput = {
  folderUuid: Scalars['ID']
  isDraft?: InputMaybe<Scalars['Boolean']>
  label: Scalars['String']
  name: Scalars['String']
  uuid: Scalars['ID']
}

export type InfoField = InputFields & {
  __typename?: 'InfoField'
  /** A number between 1 and 6 determining the field's column width. */
  columns?: Maybe<Scalars['Int']>
  condition: Array<Statement>
  group?: Maybe<Scalars['String']>
  helpText?: Maybe<Scalars['String']>
  isLabelCentered?: Maybe<Scalars['Boolean']>
  isRequired?: Maybe<Scalars['Boolean']>
  /** the label of the object which is shown to the user */
  label: Scalars['String']
  /** the name of the object which is used to identify it in code */
  name: Scalars['String']
  position: Scalars['Int']
  uuid: Scalars['ID']
}

export type Input =
  | CheckBox
  | FileUpload
  | InfoField
  | NextBlockButton
  | RepeatBlockButton
  | Select
  | SubmitFormButton
  | TextArea
  | TextField

export type InputFields = {
  /** A number between 1 and 6 determining the field's column width. */
  columns?: Maybe<Scalars['Int']>
  condition: Array<Statement>
  group?: Maybe<Scalars['String']>
  helpText?: Maybe<Scalars['String']>
  isLabelCentered?: Maybe<Scalars['Boolean']>
  isRequired?: Maybe<Scalars['Boolean']>
  /** the label of the object which is shown to the user */
  label: Scalars['String']
  /** the name of the object which is used to identify it in code */
  name: Scalars['String']
  position: Scalars['Int']
  uuid: Scalars['ID']
}

export enum InputType {
  CheckBox = 'CheckBox',
  FileUpload = 'FileUpload',
  InfoField = 'InfoField',
  NextBlockButton = 'NextBlockButton',
  RepeatBlockButton = 'RepeatBlockButton',
  Select = 'Select',
  SubmitFormButton = 'SubmitFormButton',
  TextArea = 'TextArea',
  TextField = 'TextField',
}

export type Mutation = {
  __typename?: 'Mutation'
  createFolder: Folder
  deleteFolder: Scalars['Boolean']
  deleteForm: Scalars['Boolean']
  moveForm: Scalars['Boolean']
  saveForm?: Maybe<Form>
  setFormStatus?: Maybe<Form>
  setFormTemplate?: Maybe<Scalars['Markdown']>
  submitForm: Submission
  updateFolder: Folder
  uploadFile?: Maybe<Scalars['String']>
}

export type MutationCreateFolderArgs = {
  input: FolderInput
}

export type MutationDeleteFolderArgs = {
  uuid: Scalars['ID']
}

export type MutationDeleteFormArgs = {
  uuid: Scalars['ID']
}

export type MutationMoveFormArgs = {
  folderUuid: Scalars['String']
  formUuid: Scalars['String']
}

export type MutationSaveFormArgs = {
  blocks: Array<BlockInput>
  fields: Array<FieldInput>
  form: FormInput
  options: Array<OptionInput>
  sites: Array<SiteInput>
}

export type MutationSetFormStatusArgs = {
  isDraft: Scalars['Boolean']
  uuid: Scalars['ID']
}

export type MutationSetFormTemplateArgs = {
  template: Scalars['Markdown']
  uuid: Scalars['String']
}

export type MutationSubmitFormArgs = {
  input: SubmissionInput
}

export type MutationUpdateFolderArgs = {
  input: FolderInput
  uuid: Scalars['ID']
}

export type MutationUploadFileArgs = {
  input: Scalars['Upload']
  label?: InputMaybe<Scalars['String']>
}

export type NextBlockButton = InputFields & {
  __typename?: 'NextBlockButton'
  /** A number between 1 and 6 determining the field's column width. */
  columns?: Maybe<Scalars['Int']>
  condition: Array<Statement>
  group?: Maybe<Scalars['String']>
  helpText?: Maybe<Scalars['String']>
  isLabelCentered?: Maybe<Scalars['Boolean']>
  isRequired?: Maybe<Scalars['Boolean']>
  /** the label of the object which is shown to the user */
  label: Scalars['String']
  /** the name of the object which is used to identify it in code */
  name: Scalars['String']
  position: Scalars['Int']
  uuid: Scalars['ID']
}

export type Option = {
  __typename?: 'Option'
  condition: Array<Statement>
  helpText?: Maybe<Scalars['String']>
  /** the label of the object which is shown to the user */
  label: Scalars['String']
  siteLinkUuid?: Maybe<Scalars['String']>
  uuid: Scalars['ID']
  value: Scalars['String']
}

export type OptionInput = {
  condition?: InputMaybe<Array<InputMaybe<StatementInput>>>
  helpText?: InputMaybe<Scalars['String']>
  label: Scalars['String']
  selectUuid: Scalars['String']
  siteLinkUuid?: InputMaybe<Scalars['String']>
  uuid: Scalars['ID']
  value: Scalars['String']
}

export type Page = {
  index: Scalars['Int']
  size: Scalars['Int']
}

export type Query = {
  __typename?: 'Query'
  allFolders: Array<Folder>
  allTemplates: Array<Block>
  block?: Maybe<Block>
  folder?: Maybe<Folder>
  form?: Maybe<Form>
  formTemplate?: Maybe<Scalars['Markdown']>
}

export type QueryAllFoldersArgs = {
  pagination?: InputMaybe<Page>
}

export type QueryBlockArgs = {
  uuid: Scalars['String']
}

export type QueryFolderArgs = {
  uuid: Scalars['String']
}

export type QueryFormArgs = {
  uuid: Scalars['String']
}

export type QueryFormTemplateArgs = {
  uuid: Scalars['String']
}

/** Button which should link to the first Site of the current Block. */
export type RepeatBlockButton = InputFields & {
  __typename?: 'RepeatBlockButton'
  /** A number between 1 and 6 determining the field's column width. */
  columns?: Maybe<Scalars['Int']>
  condition: Array<Statement>
  group?: Maybe<Scalars['String']>
  helpText?: Maybe<Scalars['String']>
  isLabelCentered?: Maybe<Scalars['Boolean']>
  isRequired?: Maybe<Scalars['Boolean']>
  /** the label of the object which is shown to the user */
  label: Scalars['String']
  /** the name of the object which is used to identify it in code */
  name: Scalars['String']
  position: Scalars['Int']
  uuid: Scalars['ID']
}

export type Select = InputFields & {
  __typename?: 'Select'
  /** A number between 1 and 6 determining the field's column width. */
  columns?: Maybe<Scalars['Int']>
  condition: Array<Statement>
  group?: Maybe<Scalars['String']>
  helpText?: Maybe<Scalars['String']>
  /** indicates wether Select should be displayed normally or as a group of Buttons. Normally, branching points are used to display/hide a group of inputs. */
  isBranchingPoint: Scalars['Boolean']
  isLabelCentered?: Maybe<Scalars['Boolean']>
  isRequired?: Maybe<Scalars['Boolean']>
  /** the label of the object which is shown to the user */
  label: Scalars['String']
  /** the name of the object which is used to identify it in code */
  name: Scalars['String']
  options: Array<Option>
  position: Scalars['Int']
  uuid: Scalars['ID']
}

/** a site groups multiple form elements together as a logical unit */
export type Site = {
  __typename?: 'Site'
  inputs: Array<Input>
  /** the label of the object which is shown to the user */
  label: Scalars['String']
  /** the name of the object which is used to identify it in code */
  name: Scalars['String']
  position: Scalars['Int']
  uuid: Scalars['ID']
}

export type SiteInput = {
  blockUuid: Scalars['ID']
  label: Scalars['String']
  name: Scalars['String']
  position: Scalars['Int']
  uuid: Scalars['ID']
}

export type Statement = {
  __typename?: 'Statement'
  /** the logical operator connecting to the next condition, [&,|] */
  con?: Maybe<Scalars['String']>
  /** [==, !=, >, <, <=, >=] */
  operand: Scalars['String']
  /** uuid of the target field or block */
  target: Scalars['ID']
  /** target value */
  value: Scalars['String']
}

export type StatementInput = {
  /** the logical operator connecting to the next condition, [&,|] */
  con?: InputMaybe<Scalars['String']>
  /** [==, !=, >, <, <=, >=] */
  operand: Scalars['String']
  /** uuid of the target field or block */
  target: Scalars['ID']
  /** target value */
  value: Scalars['String']
}

export type Submission = {
  __typename?: 'Submission'
  csv?: Maybe<Scalars['Base64']>
  draft?: Maybe<Scalars['Base64']>
  pdf?: Maybe<Scalars['Base64']>
}

export type SubmissionInput = {
  files: Array<Scalars['String']>
  form_uuid: Scalars['String']
  payload: Scalars['JSON']
}

export type SubmitFormButton = InputFields & {
  __typename?: 'SubmitFormButton'
  /** A number between 1 and 6 determining the field's column width. */
  columns?: Maybe<Scalars['Int']>
  condition: Array<Statement>
  group?: Maybe<Scalars['String']>
  helpText?: Maybe<Scalars['String']>
  isLabelCentered?: Maybe<Scalars['Boolean']>
  isRequired?: Maybe<Scalars['Boolean']>
  /** the label of the object which is shown to the user */
  label: Scalars['String']
  /** the name of the object which is used to identify it in code */
  name: Scalars['String']
  position: Scalars['Int']
  uuid: Scalars['ID']
}

export type TextArea = InputFields & {
  __typename?: 'TextArea'
  /** A number between 1 and 6 determining the field's column width. */
  columns?: Maybe<Scalars['Int']>
  condition: Array<Statement>
  group?: Maybe<Scalars['String']>
  helpText?: Maybe<Scalars['String']>
  isLabelCentered?: Maybe<Scalars['Boolean']>
  isRequired?: Maybe<Scalars['Boolean']>
  /** the label of the object which is shown to the user */
  label: Scalars['String']
  maxLength?: Maybe<Scalars['Int']>
  /** the name of the object which is used to identify it in code */
  name: Scalars['String']
  position: Scalars['Int']
  uuid: Scalars['ID']
}

export type TextField = InputFields & {
  __typename?: 'TextField'
  /** A number between 1 and 6 determining the field's column width. */
  columns?: Maybe<Scalars['Int']>
  condition: Array<Statement>
  group?: Maybe<Scalars['String']>
  helpText?: Maybe<Scalars['String']>
  isLabelCentered?: Maybe<Scalars['Boolean']>
  isRequired?: Maybe<Scalars['Boolean']>
  /** the label of the object which is shown to the user */
  label: Scalars['String']
  /** the name of the object which is used to identify it in code */
  name: Scalars['String']
  pattern?: Maybe<Scalars['String']>
  position: Scalars['Int']
  specialType?: Maybe<Scalars['String']>
  uuid: Scalars['ID']
}
