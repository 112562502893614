import React, { FC } from 'react'
import { Input } from '../backbone/types'
import { PaperClip } from '../icons/PaperClip'
import { Spinner } from '../shared/Spinner'

interface Props {
  onChange: (e: any) => void
  input: Input
  loading: boolean
}

export const FileDropZone: FC<Props> = ({ onChange, input, loading }) => {
  return (
    <div className="w-full max-w-uploadFieldWidth border-2 border-gray-400 border-dashed p-4 rounded-lg flex flex-col items-center leading-7 text-lg">
      <h4 className="font-semibold">Dokumenten-Upload</h4>
      <p>(Format .pdf oder .jpg)</p>
      <label
        htmlFor={input.uuid}
        className="clickable px-4 py-2 rounded my-2 max-w-2xl text-white !bg-accent space-x-2 w-60 mt-4 flex justify-center items-center hover:!bg-cyan-800 hover:!opacity-100"
      >
        <input
          className="hidden"
          type="file"
          name="upload_field"
          id={input.uuid}
          onChange={onChange}
        />

        <PaperClip />
        <span>Datei auswählen</span>
        {loading && <Spinner />}
      </label>
    </div>
  )
}
