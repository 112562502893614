import React, { FC, useState } from 'react'
import { useUuid } from '../hooks/useUuid'
import { InfoIcon } from '../icons/InfoIcon'
import { MaterialSelect } from '../shared/MaterialSelect'
import { HelpTextView } from './HelpTextView'
import { SelectFieldViewProps } from './SelectField'

export const PulldownField: FC<SelectFieldViewProps> = ({
  input,
  onChange,
  value,
}) => {
  const [showHelp, setShowHelp] = useState(false)
  const uuid = useUuid()
  return (
    <div className="w-full mb-2 form-input-regular form-input-regular-pulldown ">
      <div className="w-inputFieldWidth mx-auto px-1 flex space-x-2 justify-between">
        <label
          htmlFor={uuid}
          className={'form-label inline-block text-gray-700 mb-1 leading-7 text-lg'}
        >
          {input.label + (input.isRequired ? '*' : '')}
        </label>
        {input.helpText && (
          <div
            onClick={(e) => {
              e.stopPropagation()
              setShowHelp(!showHelp)
            }}
            className="clickable flex-wrap text-sm mb-1 rounded px-2 py-1 bg-accent text-white flex space-x-2 items-center leading-3 hover:!bg-cyan-800 hover:!opacity-100"
          >
            <InfoIcon className="w-iconWidth h-iconHeight" />
            {(input?.columns || 6) > 1 && <span className="!ml-1">Info</span>}
          </div>
        )}
      </div>
      <MaterialSelect
        className="!flex-col !items-start mx-auto w-inputSpecialWidth px-1 leading-7"
        value={value || ''}
        onChange={(e) => onChange!(e.currentTarget.value)}
      >
        <option disabled value="">
          Bitte auswählen
        </option>
        {input.options.map((o, i) => (
          <option key={i}>{o?.label}</option>
        ))}
      </MaterialSelect>{' '}
      {input.helpText && (
        <HelpTextView
          helpText={input.helpText}
          open={showHelp}
          setOpen={setShowHelp}
        />
      )}
    </div>
  )
}
