import React, { FC } from 'react'

interface Props {
  label?: string
  className?: string
  value?: string
  onChange?: (v: string) => void
}

export const MaterialTextArea: FC<Props> = ({
  label,
  value,
  onChange,
  className,
}) => {
  return (
    <div className={'flex items-center ' + className}>
      {!!label && (
        <label
          htmlFor="textarea"
          className="form-label inline-block text-dark"
        >
          {label}
        </label>
      )}
      <textarea
        value={value}
        onChange={(e) => onChange?.(e.currentTarget.value)}
        rows={3}
        className="form-control block w-full px-3 py-1.5 text-base font-normal text-dark bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-dark focus:bg-white focus:border-accent focus:outline-none shadow-md"
        id="textarea"
      />
    </div>
  )
}
