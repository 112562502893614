import React, { FC } from 'react'
import { Input } from '../backbone/types'
import { useCurrentFieldGroups } from '../hooks/useCurrentFieldGroups'
import { FieldGroup } from './FieldGroup'

interface Props {
  inputs: Input[]
}

export const FieldList: FC<Props> = ({ inputs }) => {
  const groups = useCurrentFieldGroups()
  return (
    <form
      className="w-full flex flex-col justify-center items-center n--form-field-list"
      onSubmit={(e) => e.preventDefault()}
    >
      {['', ...groups].map((g) => (
        <FieldGroup
          key={g}
          group={g}
          inputs={inputs.filter((i) => (!!g ? i.group === g : !i.group))}
        />
      ))}
    </form>
  )
}
