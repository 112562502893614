import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { resetFiles } from '../redux/files'
import { clear } from '../redux/formCache'
import { reset } from '../redux/input'
import { useAppDispatch, useAppSelector } from '../redux/store'
import { useLoadForm } from './useLoadForm'

export const useCurrentForm = () => {
  const navigate = useNavigate()
  const { form: formUuid } = useParams()
  const { loading, loadQuery } = useLoadForm(formUuid!!)
  const cache = useAppSelector((state) => state.formCache)
  const dispatch = useAppDispatch()

  const isInputInitialized = useAppSelector((state) => !!state.input?.length)
  useEffect(() => {
    if (!isInputInitialized) navigate(`/${formUuid}`)
    return
  }, [isInputInitialized, navigate])

  const formHasChanged = formUuid !== cache?.uuid && !loading
  const cacheIsEmpty = !cache
  const cacheCanBeUsed = !!cache && formUuid === cache.uuid

  return useMemo(() => {
    if (loading) {
      return { loading: true, form: undefined }
    } else if (cacheCanBeUsed) {
      return { form: cache, loading: false }
    } else if (formHasChanged || cacheIsEmpty) {
      dispatch(clear())
      dispatch(reset())
      dispatch(resetFiles())
      loadQuery()
      return { loading: true, form: undefined }
    } else {
      return { loading: true, form: undefined }
    }
  }, [loading, cacheCanBeUsed, formHasChanged, cacheIsEmpty])
}
