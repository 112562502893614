import { setBlockCompletedFlag } from '../redux/input'
import { useAppDispatch, useAppSelector } from '../redux/store'

export const useSetBlockCompleted = (
  blockIndex: number,
  completed: boolean
) => {
  const dispatch = useAppDispatch()
  const currentState = useAppSelector((s) => !!s.input[blockIndex]?.isComplete)
  return () => {
    if (completed !== currentState)
      dispatch(setBlockCompletedFlag({ blockIndex, completed }))
  }
}
