import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useCurrentForm } from '../hooks/useCurrentForm'
import { useInitializeInputState } from '../hooks/useInitializeState'
import { LoadingIndicator } from '../shared/LoadingIndicator'

export const LoadFormHandler = () => {
  const { loading, form } = useCurrentForm()
  const initState = useInitializeInputState()

  useEffect(() => {
    if (!loading && !!form) initState(form)
  })

  return loading ? <LoadingIndicator /> : <Navigate to="./0" replace={true} />
}
