import { FC, SVGProps } from 'react'

export const PaperClip: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="14"
    height="21"
    viewBox="0 0 14 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.08301 20.5C3.66634 20.5 0.833008 17.6667 0.833008 14.25V5.08333C0.833008 2.58333 2.91634 0.5 5.41634 0.5C7.91634 0.5 9.99967 2.58333 9.99967 5.08333V13.4167C9.99967 15 8.66634 16.3333 7.08301 16.3333C5.49967 16.3333 4.16634 15 4.16634 13.4167V6.33333C4.16634 5.83333 4.49967 5.5 4.99967 5.5C5.49967 5.5 5.83301 5.83333 5.83301 6.33333V13.4167C5.83301 14.0833 6.41634 14.6667 7.08301 14.6667C7.74967 14.6667 8.33301 14.0833 8.33301 13.4167V5.08333C8.33301 3.5 6.99967 2.16667 5.41634 2.16667C3.83301 2.16667 2.49967 3.5 2.49967 5.08333V14.25C2.49967 16.75 4.58301 18.8333 7.08301 18.8333C9.58301 18.8333 11.6663 16.75 11.6663 14.25V3.83333C11.6663 3.33333 11.9997 3 12.4997 3C12.9997 3 13.333 3.33333 13.333 3.83333V14.25C13.333 17.6667 10.4997 20.5 7.08301 20.5Z"
      fill="white"
    />
  </svg>
)
