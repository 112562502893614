import { ApolloProvider } from '@apollo/client'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import persistStore from 'redux-persist/es/persistStore'
import { PersistGate } from 'redux-persist/integration/react'
import { App } from './App'
import { client } from './backbone/client'
import './index.css'
import { store } from './redux/store'
import { LoadingIndicator } from './shared/LoadingIndicator'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistStore(store)} loading={<LoadingIndicator />}>
      <BrowserRouter>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
