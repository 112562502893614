import { QueryHookOptions, useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { GET_FORM } from '../backbone/queries'
import { Form, QueryFormArgs } from '../backbone/types'
import { load } from '../redux/formCache'
import { useAppDispatch } from '../redux/store'

export const useLoadForm = (
  uuid: string,
  options: QueryHookOptions<{ form: Form }, QueryFormArgs> = {}
) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [lazyLoad, query] = useLazyQuery<{ form: Form }, QueryFormArgs>(
    GET_FORM,
    {
      variables: { uuid },
      ...options,
    }
  )
  useEffect(() => {
    if (query.loading) {
    } else if (!!query.data?.form) {
      dispatch(load(query.data.form))
    } else if (!!query.error) {
      navigate('/error')
    }
  }, [!!query.data?.form, dispatch, load, query.loading])

  return {
    loadQuery: () => {
      if (!query.loading) {
        lazyLoad()
      }
    },
    loading: query.loading,
  }
}
