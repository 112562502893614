import { addFile } from '../redux/files'
import { useAppDispatch, useAppSelector } from '../redux/store'

export const useFiles = () => {
  const dispatch = useAppDispatch()
  const files = useAppSelector((state) => state.files)

  return {
    files,
    addFile: (file: string) => {
      dispatch(addFile(file))
    },
  }
}
