import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Form } from '../backbone/types'
import {
  FormState,
  InsertBlockAction,
  SetFieldAction,
  ToggleBlockCompletedAction,
  ToggleSiteCompletedAction,
} from './input.types'

const initialState = [] as FormState

const getNewBlock = (blockUuid: string) => ({
  blockUuid,
  values: {},
  isComplete: false,
  completedSites: [],
})

const inputSlice = createSlice({
  initialState,
  name: 'input',
  reducers: {
    reset: () => initialState,
    setFieldValue: (state, action: SetFieldAction) => {
      const { blockIndex, fieldUuid, value } = action.payload
      state[blockIndex].values[fieldUuid] = value
    },
    setBlockCompletedFlag: (state, action: ToggleBlockCompletedAction) => {
      const { blockIndex, completed } = action.payload
      if (!!state?.[blockIndex]) state[blockIndex].isComplete = completed
    },
    insertBlockByUuid: (state, action: InsertBlockAction) => {
      const { blockUuid, currentBlockIndex } = action.payload
      const newBlock = getNewBlock(blockUuid)

      state = state.splice(currentBlockIndex + 1, 0, newBlock)
    },
    loadForm: (_, action: PayloadAction<Form>) =>
      action.payload.blocks
        .slice()
        .sort((b1, b2) => b1.position - b2.position)
        .map((b) => getNewBlock(b.uuid)),
    setSiteCompletedFlag: (state, action: ToggleSiteCompletedAction) => {
      const { currentBlockIndex, currentSiteIndex: siteUuid } = action.payload
      if (!state[currentBlockIndex]) return
      else state[currentBlockIndex].completedSites?.push(siteUuid)
    },
    unsetSiteCompletedFlag: (state, action: ToggleSiteCompletedAction) => {
      const { currentBlockIndex, currentSiteIndex: siteUuid } = action.payload
      if (!state[currentBlockIndex]) return
      else
        state[currentBlockIndex].completedSites = state[
          currentBlockIndex
        ].completedSites?.filter((cs) => cs !== siteUuid)
    },
  },
})

export const {
  reducer: inputReducer,
  actions: {
    setFieldValue,
    loadForm,
    insertBlockByUuid,
    reset,
    setBlockCompletedFlag,
    setSiteCompletedFlag,
    unsetSiteCompletedFlag,
  },
} = inputSlice
