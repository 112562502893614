import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Modal } from '../shared/Modal'

export const HelpTextView = ({
  open,
  setOpen,
  helpText,
}: {
  helpText: string
  setOpen: (v: boolean) => void
  open: boolean
}) => (
  <Modal
    bodyClassName="max-h-screen min-w-200"
    containerClassName="max-w-50vw rounded-md border border-accent"
    open={open}
    setOpen={setOpen}
  >
    <div className="prose">
      <ReactMarkdown>{helpText}</ReactMarkdown>
    </div>
  </Modal>
)
