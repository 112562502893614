import { FC, SVGProps } from 'react'

export const TrashIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="n-icon-trash"
    role="img"
    {...props}>
    <title 
    id="n-icon-trash"
    >
    Icon Trash
    </title>
    <rect 
    width="22" 
    height="22" 
    fill="transparent"
    />
    <path 
    d="M4.25 8.75V17.75C4.25 18.9875 5.2625 20 6.5 20H15.5C16.7375 20 17.75 18.9875 17.75 17.75V8.75H4.25Z" 
    fill="currentColor"
    />
    <path 
    d="M15.5 5.375V3.125C15.5 2.45 15.05 2 14.375 2H7.625C6.95 2 6.5 2.45 6.5 3.125V5.375H2V7.625H20V5.375H15.5ZM13.25 5.375H8.75V4.25H13.25V5.375Z" 
    fill="currentColor"
    />
  </svg>
)
