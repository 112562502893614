import React, { FC } from 'react'
import { useUuid } from '../hooks/useUuid'

interface Props {
  checked?: boolean
  onClick?: () => void
  className?: string
}
export const MaterialCheckbox: FC<Props> = ({
  onClick,
  checked,
  children,
  className,
}) => {
  const uuid = useUuid()
  return (
    <div className={`form-check w-full ${className}`}>
      <label
        className={`form-check-label flex inline-block text-gray-700 flex items-center content-center w-inputSpecialWidth`}
        htmlFor={uuid}
      >
        <input
          onClick={onClick}
          onChange={() => {}}
          className="form-check-input appearance-none h-4 w-4 border border-gray-400 rounded-sm bg-white focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer !accent-accent checked:text-accent"
          type="checkbox"
          id={uuid}
          checked={checked}
        />
        {children}
      </label>
    </div>
  )
}
