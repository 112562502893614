import React from 'react'
import { useCurrentForm } from '../hooks/useCurrentForm'
import { useCurrentBlock } from '../hooks/useCurrentBlock'

export const FormTitle = () => {
  const { form } = useCurrentForm()
  const { block } = useCurrentBlock()

  return (
  <>
    <h1 className="text-heading leading-9 mb-1 font-semibold text-accent">{form?.label}</h1>
    <h2 className="text-subHeading leading-8 mb-3 font-medium text-accent">{block?.label}</h2>
  </>
  )
}