import React from 'react'
import { Footer } from './layout/Footer'
import { Header } from './layout/Header'
import { ContentRouter } from './layout/ContentRouter'
import { SidebarRouter } from './layout/SidebarRouter'
import { Snackbar } from './shared/Snackbar'

export const App = () => {
  return (
  <div
      className="min-h-[60%] overflow-auto p-0 flex flex-row flex-wrap relative content-center"
      id="root"
    >
      <Header />
      <div className="max-w-8xl mx-auto flex-1 flex flex-nowrap md:px-8 tablet:px-4">
        <SidebarRouter />
        <ContentRouter />
      </div>
      <Footer />
      <Snackbar />
    </div>
  )
}
