import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useBreadcrumbs } from './useBreadcrumbs'
import { useCurrentBlock } from './useCurrentBlock'
import { useCurrentForm } from './useCurrentForm'
import { useCurrentSite } from './useCurrentSite'

export const useSiteBreadCrumbs = () => {
  const { site } = useCurrentSite()
  const { form } = useCurrentForm()
  const { block } = useCurrentBlock()

  const location = useLocation()
  const { setBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    setBreadcrumbs([
      { label: form?.label!, path: location.pathname },
      { label: block?.label!, path: location.pathname },
      { label: site?.label!, path: location.pathname },
    ])
  }, [form?.label, block?.label, site?.label])
}
