import React, { FC, useState } from 'react'
import { FieldViewProps } from '../hooks/useFieldMapping'
import { useUuid } from '../hooks/useUuid'
import { InfoIcon } from '../icons/InfoIcon'
import { MaterialTextArea } from '../shared/MaterialTextArea'
import { HelpTextView } from './HelpTextView'

export const LongTextField: FC<FieldViewProps> = ({
  input,
  value,
  onChange,
}) => {
  const [showHelp, setShowHelp] = useState(false)
  const uuid = useUuid()
  return (
    <div className="w-full mb-2 form-input-regular form-input-regular-longtext ">
      <div className="w-inputFieldWidth mx-auto px-1 flex space-x-2 justify-between">
        <label
          htmlFor={uuid}
          className={'form-label inline-block text-gray-700 mb-1 leading-7 !text-lg'}
        >
          {input.label + (input.isRequired ? '*' : '')}
        </label>
        {input.helpText && (
          <div
            onClick={(e) => {
              e.stopPropagation()
              setShowHelp(!showHelp)
            }}
            className="clickable flex-wrap text-sm mb-1 rounded px-2 py-1 bg-accent text-white flex space-x-2 items-center leading-3 hover:!bg-cyan-800 hover:!opacity-100"
          >
            <InfoIcon className="w-iconWidth h-iconHeight" />
            {(input?.columns || 6) > 1 && <span className="!ml-1">Info</span>}
          </div>
        )}
      </div>
      <MaterialTextArea
        className="!flex-col !items-start mx-auto w-inputFieldWidth px-1 !text-lg"
        value={value}
        onChange={onChange}
      />
      {input.helpText && (
        <HelpTextView
          helpText={input.helpText}
          open={showHelp}
          setOpen={setShowHelp}
        />
      )}
    </div>
  )
}
