import React, { FC, useState } from 'react'
import { Select } from '../backbone/types'
import { InfoButton } from '../shared/InfoButton'
import { HelpTextView } from './HelpTextView'

export const SelectFieldHeader: FC<{ select: Select }> = ({ select }) => {
  const [showInfo, setShowInfo] = useState(false)
  return (
    <>
      <div className="flex items-center space-x-1 mb-10 px-6 max-w-800">
        <h2 className="text-2xl font-medium text-center leading-8">{select.label}</h2>
        {select.helpText && (
          <InfoButton
            onClick={() => {
              setShowInfo(!showInfo)
            }}
          />
        )}
      </div>
      {select.helpText && (
        <HelpTextView
          helpText={select.helpText}
          open={showInfo}
          setOpen={setShowInfo}
        />
      )}
    </>
  )
}
