import React from 'react'
import { useEnvLinks } from '../hooks/useStartPageLink'

export const Error = () => {
  const { startPageUrl } = useEnvLinks()
  return (
    <div>
      <h1>Ein Fehler ist aufgetreten.</h1>
      <a href={startPageUrl}>Zurück zur Startseite</a>
    </div>
  )
}
