import { useParams } from 'react-router-dom'
import { Block } from '../backbone/types'
import { useAppSelector } from '../redux/store'

export const useSidebarSiteLinkItems = (
  blockIndex: number,
  block: Block,
  linkPrefix: string
) => {
  const {
    blockIndex: currentBlockIndex = '',
    siteIndex: currentSiteIndex = '',
  } = useParams()
  const completedSites = useAppSelector(
    (s) => s.input[blockIndex]?.completedSites || []
  )
  const isSiteActive = (siteIndex: number) =>
    parseInt(currentBlockIndex) === blockIndex &&
    siteIndex === parseInt(currentSiteIndex)

  const items =
    block?.sites
      ?.map((s, index) => ({
        ...s,
        path: `${linkPrefix}/${s.position}`,
        isUpcoming: parseInt(currentBlockIndex) < blockIndex,
        isComplete: completedSites.includes(index) && !isSiteActive(index),
      }))
      .filter((s) => s.isComplete || isSiteActive(s.position)) || []
  return items
}
