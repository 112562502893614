import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  [key: string]: string
}

type SetValueAction = PayloadAction<{ fieldUuid: string; value: any }>

const initialState: State = {}

const valueMapSlice = createSlice({
  name: 'targetValueMap',
  initialState,
  reducers: {
    reset: (state) => {
      state = {}

      return state
    },
    setValue: (state, action: SetValueAction) => {
      state[action.payload.fieldUuid] = action.payload.value
    },
  },
})

export const {
  actions: { reset, setValue },
  reducer: valueMapReducer,
} = valueMapSlice
