import { FC, HTMLProps } from 'react'

export const MaterialSelect: FC<HTMLProps<HTMLSelectElement>> = ({
  children,
  className = '',
  ...Props
}) => (
  <select
    className={
      'form-select appearance-none block px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-accent focus:outline-none shadow-md ' +
      className
    }
    aria-label="Select"
    {...Props}
  >
    {children}
  </select>
)
