import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../redux/store'
import { showSnackbar } from '../redux/ui'
import { SnackbarProps } from '../shared/Snackbar'

interface Options extends SnackbarProps {
  timeout?: number
}

export const useSnackbar = ({ timeout = 2000, text, title }: Options = {}) => {
  const dispatch = useDispatch()
  const state = useAppSelector((s) => s.ui.snackbar)
  const show = useAppSelector(
    (s) => !!s.ui.snackbar.text || !!s.ui.snackbar.text
  )
  const setShow = (options: SnackbarProps) => {
    if (options.text !== state.text || options.title !== state.title)
      dispatch(showSnackbar(options))
  }
  useEffect(() => {
    const timoutId = setTimeout(() => {
      setShow({})
    }, timeout)
    return () => clearTimeout(timoutId)
  }, [show])

  const showSnackbarMemo = useCallback(() => setShow({ text, title }), [
    text,
    title,
  ])
  return {
    state,
    showSnackbar: showSnackbarMemo,
    close: () => setShow({}),
  }
}
