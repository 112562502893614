import React, { FC } from 'react'
import { useEnvLinks } from '../hooks/useStartPageLink'
import { BackArrow } from '../icons/BackArrow'
import { CloseIcon } from '../icons/CloseIcon'
import { Logo } from '../shared/Logo'

interface Props {
  showMobile: boolean
  setShowMobile: (v: boolean) => void
}

export const SidebarContentWrapper: FC<Props> = ({
  setShowMobile,
  showMobile,
  children,
}) => {
  const { startPageUrl } = useEnvLinks()
  return (
    <div
      className={
        `${showMobile ? 'left-0' : '-left-[100%]'} ` +
        'w-sidebarWidth md:overflow-scroll tablet:overflow-auto tablet:w-full h-screen bg-white md:z-50 tablet:z-0 tablet:shadow-none shadow-mobileSidebarShadow transition-all duration-1000 delay-0 tablet:pt-11 fixed top-0 tablet:relative md:p-0 tablet:left-0'
      }
    >
      <div className="tablet:hidden flex justify-between items-center tablet:mb-8 p-4 md:px-8 border-b h-16 border-borderGray">
        <a className="" href={startPageUrl}>
          <Logo />
        </a>
        <CloseIcon
          onClick={() => setShowMobile(false)}
          className="clickable w-7 h-7 text-font"
        />
      </div>
      <div className="md:p-8 md:pb-14 tablet:pb-20 tablet:p-4 tablet:overflow-auto">
        <a href={startPageUrl} className="md:mb-8 tablet:mb-4 flex items-center text-font">
          <BackArrow className="mr-2.5" />
          <span className="md:text-xl md:leading-5 tablet:text-lg leading-5">Startseite</span>
        </a>
        {children}
      </div>
    </div>
  )
}