import { FC } from 'react'
import { NavLink, useMatch } from 'react-router-dom'
import { GreenCheckbox } from '../icons/GreenCheckbox'

interface Props {
  path: string
  label: string
  indent?: boolean
  isComplete: boolean
  matchesNotCondition?: boolean
}

export const SidebarLink: FC<Props> = ({
  label,
  path,
  indent = false,
  isComplete,
  matchesNotCondition,
}) => {
  const match = useMatch({ path, end: false })
  const isActive = !!match

  return !!matchesNotCondition ? null : (
    <li
      className={`flex items-center md:text-xl md:leading-menuLeading tablet:text-lg tablet:leading-9 text-font border-l-borderThick ml-negativeMenuMargin pl-5 ${
        indent ? 'type-page' : 'type-block'
      } ${isActive && 'text-current'}`}
    >
      <NavLink
        className={`flex items-baseline pb-1 md:leading-menuLeading tablet:leading-9 text-font md:text-menuFont tablet:text-lg space-x-2 ${
          isActive && indent && 'text-accent'
        } ${
          indent
            ? 'type-page border-l-borderThick border-accent ml-negativeNavLinkMargin pl-10'
            : 'type-block'
        }
        ${isActive ? '' : 'cursor-default text-gray-500'}`}
        to={path}
      >
        <span>&bull;</span>
        <span className="leading-7">{label}</span>
      </NavLink>
      {isComplete && !indent && <GreenCheckbox className="ml-2" />}
    </li>
  )
}
