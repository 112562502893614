import React from 'react'
import { FieldList } from './FieldList'
import { FormTitle } from './FormTitle'
import { useCurrentSite } from '../hooks/useCurrentSite'
import { LoadingIndicator } from '../shared/LoadingIndicator'
import { useScrollToTop } from '../hooks/useScrollToTop'
import { useParams } from 'react-router-dom'

export const SiteView = () => {
  const { site, loading } = useCurrentSite()
  const { blockIndex = '', siteIndex = '' } = useParams()
  useScrollToTop([blockIndex, siteIndex])
  return loading ? (
    <LoadingIndicator />
  ) : (
    <>
      <FormTitle />
      {site && <FieldList inputs={site.inputs} />}
    </>
  )
}
