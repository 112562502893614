import { ReactNode } from 'react'
import { useSnackbar } from '../hooks/useSnackbar'

export interface SnackbarProps {
  title?: string | ReactNode
  text?: string | ReactNode
}

export const Snackbar = () => {
  const {
    state: { text, title },
    close,
  } = useSnackbar()
  if (!text && !title) return null
  return (
    <div className="fixed top-[80%] w-full flex justify-center p-4 animate-fade">
      <div
        className="bg-slate-100 border border-slate-400 text-slate-700 px-4 py-3 pr-12 rounded mx-auto relative fixed-bottom"
        role="alert"
      >
        {!!title && <strong className="font-bold mr-1">{title}</strong>}
        <span className="block sm:inline">{text}</span>
        <span
          className="absolute top-0 bottom-0 right-0 px-4 py-3"
          onClick={close}
        >
          <svg
            className="fill-current h-6 w-6 text-slate-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Schließen</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      </div>
    </div>
  )
}
