import React from 'react'
import { useEnvLinks } from '../hooks/useStartPageLink'
import { Button } from '../shared/Button'
import { Logo } from '../shared/Logo'
import { HelpButton } from './HelpButton'

export const Header = () => {
  const { homepageUrl, startPageUrl } = useEnvLinks()

  return (
    <div className="h-16 border-b bg-white border-borderGray w-full">
      <header className="h-full flex items-center justify-between max-w-8xl md:px-8 tablet:px-4 w-full mx-auto">
        <a href={startPageUrl}>
          <Logo />
        </a>
        <div className="flex flex-row items-center">
          <HelpButton />
          <a className="p-0 mr-6 text-lg text-dark" href={`${startPageUrl}/datenschutz`}>
            Datenschutz
          </a>
          <Button className="!bg-accent !text-white font-semibold text-lg leading-6 hover:opacity-100 hover:!bg-cyan-800">
            <a href={homepageUrl}>Zur Webseite des Notars</a>
          </Button>
        </div>
      </header>
    </div>
  )
}
