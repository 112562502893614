import React, { useState } from 'react'
import { useCurrentForm } from '../hooks/useCurrentForm'
import { LoadingIndicator } from '../shared/LoadingIndicator'
import { SidebarBlockLinks } from './SidebarBlockLinks'
import { SidebarContentWrapper } from './SidebarContentWrapper'
import { SidebarMenuButton } from './SidebarMenuButton'

export const Sidebar = () => {
  const { form, loading } = useCurrentForm()
  const [showMobile, setShowMobile] = useState(false)

  if (!form) return null

  return loading ? (
    <LoadingIndicator />
  ) : (
    <div className="flex flex-col md:w-16 tablet:w-80">
      <SidebarMenuButton setShowMobile={setShowMobile} />
      <SidebarContentWrapper
        showMobile={showMobile}
        setShowMobile={setShowMobile}
      >
        <SidebarBlockLinks />
      </SidebarContentWrapper>
    </div>
  )
}
