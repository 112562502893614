import { createSlice } from '@reduxjs/toolkit'
import { Form } from '../backbone/types'

const formCacheSlice = createSlice({
  name: 'formCache',
  initialState: { uuid: '' } as Form & { shouldReset: boolean | undefined },
  reducers: {
    fetch: (state) => {
      state.shouldReset = false
    },
    load: (_, action) => action.payload,
    clear: (state) => {
      state.shouldReset = true
    },
  },
})

export const {
  reducer: formCacheReducer,
  actions: { clear, load, fetch },
} = formCacheSlice
