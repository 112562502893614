import { useMutation } from '@apollo/client'
import { MutationUploadFileArgs } from '../backbone/types'
import { UPLOAD_FILE } from '../backbone/queries'

export const useUploadFile = (callback?: (val: string) => void) => {
  const mutation = useMutation<{ uploadFile: string }, MutationUploadFileArgs>(
    UPLOAD_FILE,
    { onCompleted: ({ uploadFile }) => callback?.(uploadFile) }
  )
  return mutation
}
