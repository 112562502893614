import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import { Input } from '../backbone/types'
import { useEvalCondition } from '../hooks/useEvalCondition'
import { useField } from '../hooks/useField'
import { useFieldMapping } from '../hooks/useFieldMapping'

interface Props {
  input: Input
}

export const FieldProxy: FC<Props> = ({ input }) => {
  const { blockIndex = '' } = useParams()
  const { setValue, value } = useField(
    parseInt(blockIndex),
    input.uuid,
    input.__typename === 'Select'
  )
  const View = useFieldMapping(input.__typename)

  const conditionMatches = useEvalCondition(input.condition)
  if (!conditionMatches) return null
  if (blockIndex === '') return null

  return !!View ? (
    <View value={value as any} onChange={setValue} input={input} />
  ) : (
    <div>{input.__typename}</div>
  )
}
