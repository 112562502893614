import React, { FC } from 'react'
import { FieldViewProps } from '../hooks/useFieldMapping'
import { useSubmitForm } from '../hooks/useSubmitForm'
import { NavButton } from '../shared/NavButton'
import { Spinner } from '../shared/Spinner'

export const SubmitFormField: FC<FieldViewProps> = ({ input }) => {
  const { loading, submit } = useSubmitForm()

  return (
    <NavButton
      target="."
      onBeforeClick={submit}
      disabled={loading}
      className="flex space-x-1 items-center justify-center disabled:opacity-40"
    >
      <span>{input.label}</span> {loading && <Spinner />}
    </NavButton>
  )
}
