import React, { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import { FieldViewProps } from '../hooks/useFieldMapping'

export const InfoField: FC<FieldViewProps> = ({ input }) => {
  return (
    <div className="w-full form-input-regular-info">
      <div
        className={`flex flex-col mx-auto px-6 space-x-1 justify-center my-4 max-w-800 ${
          input.isLabelCentered ? 'items-center' : 'start-left'
        }`}
      >
        <h3 className="text-xl font-semibold mb-3 leading-7">{input.label}</h3>
        {!!input.helpText && (
          <div className={`prose !ml-0 !max-w-none leading-7 ${input.isLabelCentered ? 'text-justify' : 'text-left'}`}>
            <ReactMarkdown>{input.helpText}</ReactMarkdown>
          </div>
        )}
      </div>
    </div>
  )
}
