import React, { FC } from 'react'
import { FileListItem } from './FileListItem'

export const FileList: FC<{
  files: string[]
  setFiles: (v: string[]) => void
}> = ({ files, setFiles }) => {
  const removeFn = (index: number) => () =>
    setFiles(files.filter((_, i) => i !== index))
  return !files.length ? null : (
    <div className="my-4 w-full max-w-md ">
      <h4 className="my-2 font-bold">Hochgeladene Dokumente</h4>
      <div className="space-y-2">
        {files.map((filename, i) => (
          <FileListItem key={i} label={filename} remove={removeFn(i)} />
        ))}
      </div>
    </div>
  )
}
